/**
 * @flow
 * @relayHash b3b5d3d4a94977508b68afaafb07ff22
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StudentProfileFields_user$ref = any;
type TeacherProfileFields_user$ref = any;
export type UserProfilePageQueryVariables = {||};
export type UserProfilePageQueryResponse = {|
  +user: ?{|
    +Type: ?string,
    +Email: ?string,
    +firstName: ?string,
    +graduationYear: ?number,
    +school: ?{|
      +id: ?string,
      +name: ?string,
    |},
    +$fragmentRefs: StudentProfileFields_user$ref & TeacherProfileFields_user$ref,
  |}
|};
export type UserProfilePageQuery = {|
  variables: UserProfilePageQueryVariables,
  response: UserProfilePageQueryResponse,
|};
*/


/*
query UserProfilePageQuery {
  user {
    ...StudentProfileFields_user
    ...TeacherProfileFields_user
    Type
    Email
    firstName
    graduationYear
    school {
      id
      name
    }
    id
  }
}

fragment StudentProfileFields_user on User {
  id
  firstName
  lastName
  graduationYear
  school {
    id
    name
    departments {
      id
      name
    }
  }
  major
  legacyId
  email
}

fragment TeacherProfileFields_user on User {
  firstName
  lastName
  school {
    name
    id
  }
  legacyId
  teacher {
    emailAlerts
    legacyId
    school {
      name
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Type",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "Email",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "graduationYear",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "legacyId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserProfilePageQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "school",
            "storageKey": null,
            "args": null,
            "concreteType": "School",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "FragmentSpread",
            "name": "StudentProfileFields_user",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "TeacherProfileFields_user",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserProfilePageQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "school",
            "storageKey": null,
            "args": null,
            "concreteType": "School",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "departments",
                "storageKey": null,
                "args": null,
                "concreteType": "Department",
                "plural": true,
                "selections": (v6/*: any*/)
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "major",
            "args": null,
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teacher",
            "storageKey": null,
            "args": null,
            "concreteType": "Teacher",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "emailAlerts",
                "args": null,
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "school",
                "storageKey": null,
                "args": null,
                "concreteType": "School",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          },
          (v0/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserProfilePageQuery",
    "id": null,
    "text": "query UserProfilePageQuery {\n  user {\n    ...StudentProfileFields_user\n    ...TeacherProfileFields_user\n    Type\n    Email\n    firstName\n    graduationYear\n    school {\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment StudentProfileFields_user on User {\n  id\n  firstName\n  lastName\n  graduationYear\n  school {\n    id\n    name\n    departments {\n      id\n      name\n    }\n  }\n  major\n  legacyId\n  email\n}\n\nfragment TeacherProfileFields_user on User {\n  firstName\n  lastName\n  school {\n    name\n    id\n  }\n  legacyId\n  teacher {\n    emailAlerts\n    legacyId\n    school {\n      name\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ac58f43247f124a3be5a39bad05e42d';
module.exports = node;
