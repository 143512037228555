/**
 * @flow
 * @relayHash 964261d84a9f8a806ea0b7ea691a67b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfessorNoteHeader_note$ref = any;
type ProfessorNote_note$ref = any;
export type teacherNotesInput = {|
  class?: ?string,
  comment?: ?string,
  id?: ?string,
  ratingId: string,
  teacherId: string,
|};
export type ProfessorNoteEditorMutationVariables = {|
  teacherNote: teacherNotesInput
|};
export type ProfessorNoteEditorMutationResponse = {|
  +teacherNoteCreate: ?{|
    +errors: ?string,
    +teacherNote: ?{|
      +id: ?string,
      +teacherId: ?string,
      +$fragmentRefs: ProfessorNote_note$ref & ProfessorNoteHeader_note$ref,
    |},
  |}
|};
export type ProfessorNoteEditorMutation = {|
  variables: ProfessorNoteEditorMutationVariables,
  response: ProfessorNoteEditorMutationResponse,
|};
*/


/*
mutation ProfessorNoteEditorMutation(
  $teacherNote: teacherNotesInput!
) {
  teacherNoteCreate(teacherNote: $teacherNote) {
    errors
    teacherNote {
      id
      teacherId
      ...ProfessorNote_note
      ...ProfessorNoteHeader_note
    }
  }
}

fragment ProfessorNote_note on TeacherNotes {
  comment
  ...ProfessorNoteHeader_note
  ...ProfessorNoteFooter_note
}

fragment ProfessorNoteHeader_note on TeacherNotes {
  createdAt
  updatedAt
}

fragment ProfessorNoteFooter_note on TeacherNotes {
  legacyId
  flagStatus
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teacherNote",
    "type": "teacherNotesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teacherNote",
    "variableName": "teacherNote"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "errors",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "teacherId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProfessorNoteEditorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "teacherNoteCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TeacherNoteCreateResponse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teacherNote",
            "storageKey": null,
            "args": null,
            "concreteType": "TeacherNotes",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "ProfessorNote_note",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "ProfessorNoteHeader_note",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProfessorNoteEditorMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "teacherNoteCreate",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "TeacherNoteCreateResponse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "teacherNote",
            "storageKey": null,
            "args": null,
            "concreteType": "TeacherNotes",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "comment",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "legacyId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "flagStatus",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ProfessorNoteEditorMutation",
    "id": null,
    "text": "mutation ProfessorNoteEditorMutation(\n  $teacherNote: teacherNotesInput!\n) {\n  teacherNoteCreate(teacherNote: $teacherNote) {\n    errors\n    teacherNote {\n      id\n      teacherId\n      ...ProfessorNote_note\n      ...ProfessorNoteHeader_note\n    }\n  }\n}\n\nfragment ProfessorNote_note on TeacherNotes {\n  comment\n  ...ProfessorNoteHeader_note\n  ...ProfessorNoteFooter_note\n}\n\nfragment ProfessorNoteHeader_note on TeacherNotes {\n  createdAt\n  updatedAt\n}\n\nfragment ProfessorNoteFooter_note on TeacherNotes {\n  legacyId\n  flagStatus\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c80a03ad5105fa49a48ad7fd0b24cfe3';
module.exports = node;
