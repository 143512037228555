import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Search, { DebouncedSearchInput, SearchInputContainer } from '@components/Search'
import PropTypes from 'prop-types'
import { graphql } from 'react-relay'
import Apple from '@assets/images/js/Apple'
import GraduationCap from '@assets/images/js/GraduationCap'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import SearchBlack from '@assets/images/search-black.svg'
import CloseIcon from '@assets/images/close-icon.svg'
import { setUserSchool } from '@utils/getAndSetUserSchool'
import useUserInfo from '@hooks/useUserInfo'
import PLACEHOLDER from '@enums/PLACEHOLDER'
import ROUTE from '@enums/ROUTE'
import { StyledSearchTypeaheadDropdown } from './SearchTypeahead'
import { breakpointLargeAndBelow } from './StyledComponents/theme/helpers/breakpoints'

const teachersQuery = graphql`
  query NewSearchTeachersQuery(
    $query: TeacherSearchQuery!
    $count: Int
    $includeCompareProfessors: Boolean!
  ) {
    newSearch {
      teachers(query: $query, first: $count) {
        didFallback
        edges {
          cursor
          node {
            id
            legacyId
            firstName
            lastName
            department
            departmentId
            school {
              legacyId
              name
            }
            ...CompareProfessorsColumn_teacher @include(if: $includeCompareProfessors)
          }
        }
      }
    }
  }
`

const schoolsQuery = graphql`
  query NewSearchSchoolsQuery($query: SchoolSearchQuery!) {
    newSearch {
      schools(query: $query) {
        edges {
          cursor
          node {
            id
            legacyId
            name
            city
            state
            departments {
              id
              name
            }
            numRatings
            avgRatingRounded
            summary {
              campusCondition
              campusLocation
              careerOpportunities
              clubAndEventActivities
              foodQuality
              internetSpeed
              libraryCondition
              schoolReputation
              schoolSafety
              schoolSatisfaction
              socialActivities
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

function MagnifyingGlass() {
  return <img src={SearchBlack} alt="Magnifying glass" />
}

export const NEW_SEARCH_ICONS = {
  NONE: 'NONE',
  HOME_PAGE: 'HOME_PAGE',
  TEACHER_RATINGS_PAGE: 'TEACHER_RATINGS_PAGE'
}

const iconConfig = {
  [NEW_SEARCH_ICONS.NONE]: {
    default: null,
    teachers: null,
    schools: null
  },
  [NEW_SEARCH_ICONS.HOME_PAGE]: {
    default: MagnifyingGlass,
    teachers: Apple,
    schools: GraduationCap
  },
  [NEW_SEARCH_ICONS.TEACHER_RATINGS_PAGE]: {
    default: MagnifyingGlass,
    teachers: null,
    schools: null
  }
}

export const StyledSearchWrapper = styled.div`
  position: relative;
  width: 100%;

  ${StyledSearchTypeaheadDropdown} {
    box-shadow: 0px 4px 4px rgba(151, 151, 151, 0.3);
    top: 50px;
  }

  ${SearchInputContainer} {
    max-width: ${props => (props.isCompareProfessorsPage ? 'none' : '545px')};
    width: 100%;
  }
`

const valueColor = css`
  ${({ theme }) => theme.color.gray10}
`

const placeholderColor = css`
  ${({ theme }) => theme.color.gray9}
`

const StyledSearch = styled(Search)`
  ${DebouncedSearchInput} {
    border: 0;
    border-radius: ${({ iconCategory }) => (iconCategory === null ? '42px 42px 0 0' : '43px')};
    color: ${props => (props.inputValue === '' ? placeholderColor : valueColor)};
    font-size: ${({ theme }) => theme.fontSize.teacherCardName};
    max-width: ${props => (props.isCompareProfessorsPage ? 'none' : '545px')};
    outline: none;
    width: 100%;

    &::placeholder {
      color: ${({ theme }) => theme.color.gray9};
    }

    ${StyledSearchTypeaheadDropdown} {
      max-width: ${props => (props.isCompareProfessorsPage ? 'none' : '545px')};
      width: 100%;
    }
  }
`

export const SearchIconWrapper = styled.div`
  left: 27px;
  position: absolute;
  top: 17px;
  width: fit-content;
  z-index: 2;
`

export const CloseIconWrapper = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 36px;
  top: 14px;

  ${breakpointLargeAndBelow(
    css`
      right: 17px;
    `
  )}
`

const getSearchIcon = (searchCategory, inputIconConfig) => {
  if (searchCategory === SEARCH_CATEGORY.SCHOOLS) {
    return inputIconConfig?.schools
  }

  if (searchCategory === SEARCH_CATEGORY.TEACHERS) {
    return inputIconConfig?.teachers
  }

  return inputIconConfig?.default
}

const buildOnItemSelected = onItemSelectedConfig => (history, event, itemType, item, setIsOpen) => {
  const { allowLink, user, compareSchools } = onItemSelectedConfig

  /**
   * This bypasses saving the school to user profile if the search is on
   * the compare schools page, but keeps functionality if search is on homepage
   */
  if (itemType === SEARCH_CATEGORY.SCHOOLS && !compareSchools) {
    setUserSchool(user, item)
  }

  if (onItemSelectedConfig?.onItemSelected) {
    const config = { history, event, itemType, item, setIsOpen, ...onItemSelectedConfig }

    onItemSelectedConfig.onItemSelected(config)
  }

  if (allowLink && itemType === SEARCH_CATEGORY.TEACHERS) {
    history.push({ pathname: ROUTE.SHOW_RATINGS.replace(':tid', item.legacyId) })
  }
}

export default function NewSearch(props) {
  const {
    schoolID,
    searchCategory,
    onItemSelected,
    iconType,
    hasCloseIcon,
    setHasValue = () => {},
    closeIconOnClick = () => {},
    inputRef,
    hidePlaceholder,
    allowLink,
    compareSchools,
    isCompareProfessorsPage = false,
    isPrimaryProfessor,
    count = 10,
    inputValue,
    setInputValue = () => {},
    setError
  } = props

  const isSchoolSearch = searchCategory === SEARCH_CATEGORY.SCHOOLS

  const [iconCategory, setIconCategory] = useState(searchCategory)

  const newSearchIconConfig = iconType ? iconConfig[iconType] : iconConfig[NEW_SEARCH_ICONS.NONE]

  const Icon = getSearchIcon(iconCategory, newSearchIconConfig)

  useEffect(() => {
    setHasValue(iconCategory === null)
  }, [iconCategory])

  useEffect(() => {
    if (iconCategory !== searchCategory) {
      setIconCategory(searchCategory)
    }
  }, [searchCategory])

  const searchQueryConfig = {
    isNewSearch: true,
    setInputIconCategory: setIconCategory,
    schoolID,
    isSchoolSearch,
    fallback: true
  }

  const placeholder = isSchoolSearch ? PLACEHOLDER.SCHOOL_SEARCH : PLACEHOLDER.PROF_SEARCH

  const user = useUserInfo()

  const searchOnItemSelected = buildOnItemSelected({
    onItemSelected,
    user,
    allowLink,
    compareSchools,
    isPrimaryProfessor,
    ...props
  })

  return (
    <StyledSearchWrapper isCompareProfessorsPage={isCompareProfessorsPage}>
      <SearchIconWrapper>{Icon && <Icon />}</SearchIconWrapper>
      <StyledSearch
        {...props}
        query={isSchoolSearch ? schoolsQuery : teachersQuery}
        searchQueryConfig={searchQueryConfig}
        category={searchCategory}
        placeholder={hidePlaceholder ? null : placeholder}
        iconCategory={iconCategory}
        setIconCategory={setIconCategory}
        setInputIconCategory={setIconCategory}
        onItemSelected={searchOnItemSelected}
        inputRef={inputRef}
        isCompareProfessorsPage={isCompareProfessorsPage}
        count={count}
        inputValue={inputValue} // input value (what is typed) is different from the query value
        setInputValue={setInputValue}
        setError={setError}
      />
      {hasCloseIcon && (
        /* blurring occurs on the onMouseDown event, and we want the ability to preventDefault blurring */
        <CloseIconWrapper
          onMouseDown={event => {
            event.preventDefault()

            if (setInputValue) setInputValue('')
            closeIconOnClick()
          }}
          title="Clear"
        >
          <img src={CloseIcon} alt="Close Icon" />
        </CloseIconWrapper>
      )}
    </StyledSearchWrapper>
  )
}

NewSearch.propTypes = {
  isSchoolSearch: PropTypes.bool,
  searchCategory: PropTypes.oneOf(Object.values(SEARCH_CATEGORY)),
  schoolID: PropTypes.string,
  onItemSelected: PropTypes.func,
  iconType: PropTypes.oneOf(Object.values(NEW_SEARCH_ICONS)),
  hasCloseIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  closeIconOnClick: PropTypes.func,
  hasValue: PropTypes.bool,
  setHasValue: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.node
    }),
    PropTypes.shape({
      current: null
    })
  ]),
  setValue: PropTypes.func,
  fallback: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
  allowLink: PropTypes.bool, // we don't want link redirect on forms with search
  compareSchools: PropTypes.bool,
  isCompareProfessorsPage: PropTypes.bool,
  isPrimaryProfessor: PropTypes.bool,
  count: PropTypes.number,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
