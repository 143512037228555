import React from 'react'
import styled from 'styled-components'
import { graphql } from 'react-relay'
import PropTypes from 'prop-types'
import Form from '@components/Form/Form'
import TextInput from '@components/Form/TextInput'
import FormButton from '@components/Form/FormButton'
import LABEL_STYLE from '@enums/LABEL_STYLE'
import FormSchoolSelector from '@components/Form/FormSchoolSelector'
import { ButtonAttributes, TextButton } from '@StyledComponents/Buttons'
import useGlobalContext from '@hooks/useGlobalContext'
import DepartmentSelection from '@pages/AddProfessor/DepartmentSelection'
import SignupModalHeader from './SignupModalHeader'

const StyledDetailsForm = styled(Form)`
  margin-top: 42px;
`
const SkipButton = styled(TextButton)`
  font-size: ${props => props.theme.fontSize.medium};
  margin-top: 14px;
`

const DetailsFormHeader = styled(SignupModalHeader)`
  font-weight: ${props => props.theme.fontWeight.boldest};
  margin-right: 8px;
  margin-top: 5px;
`

const SubmitButton = styled(FormButton)`
  margin-top: 46px;
  margin-top: 54px;
  width: 100%;

  ${ButtonAttributes}
`

const userUpdateMutation = graphql`
  mutation DetailsFormMutation($user: UserInputObject!) {
    userUpdate(user: $user) {
      user {
        id
        email
        firstName
        lastName
        school {
          id
          name
        }
        major
      }

      validation {
        ...FormController_validation
      }
    }
  }
`
export default function DetailsForm({ newEmail, onClose }) {
  const { closeActiveModal } = useGlobalContext()
  const completeSignup = () => {
    onClose()
    closeActiveModal()
  }

  // TODO: return below ignored function to coverage check once `onSuccess` is testable
  /* istanbul ignore next */
  const onDetailsFormSuccess = () => {
    completeSignup()
  }
  const onDetailsFormSkip = () => {
    completeSignup()
  }

  return (
    <React.Fragment>
      <DetailsFormHeader>A few more details</DetailsFormHeader>
      <StyledDetailsForm
        getVariables={model => ({
          user: {
            email: newEmail,
            firstName: model.firstName,
            lastName: model.lastName,
            schoolId: model.school,
            major: model.major
          }
        })}
        mutation={userUpdateMutation}
        onSuccess={() => onDetailsFormSuccess()}
      >
        <TextInput
          name="firstName"
          label="First Name"
          autoComplete="given-name"
          labelStyle={LABEL_STYLE.TOP}
        />
        <TextInput
          name="lastName"
          label="Last Name"
          autoComplete="family-name"
          labelStyle={LABEL_STYLE.TOP}
        />
        <FormSchoolSelector inModal name="school" label="School" labelStyle={LABEL_STYLE.TOP} />
        <DepartmentSelection
          name="major"
          label="Field of Study"
          labelStyle={LABEL_STYLE.TOP}
          searchable
        />
        <SubmitButton type="submit">Complete Sign Up</SubmitButton>
      </StyledDetailsForm>
      <SkipButton onClick={() => onDetailsFormSkip()}>Skip for now</SkipButton>
    </React.Fragment>
  )
}

DetailsForm.propTypes = {
  newEmail: PropTypes.string.isRequired,
  onClose: PropTypes.func
}
