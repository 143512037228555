import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RatingTypes from '@types/Rating.types'
import PageWrapper from '@components/PageWrapper'
import AdRail from '@components/Ads/AdRail'
import { getPageContentMaxWidth } from '@components/StyledComponents/theme/helpers/breakpoints'
import Rating from '@components/Rating/Rating'
import RatingNotFound from '@pages/RatingNotFound'
import SEOMetaInfo from '@components/SEOMetaInfo'
import AdLeaderboard from '@components/Ads/AdLeaderboard'
import RatingPermalinkHeader from './RatingPermalinkHeader'

export const RatingPermalinkContainer = styled.div`
  ${({ theme }) => getPageContentMaxWidth(theme)};
  min-height: 100vh;
  padding: 24px 20px;
`

const isRemoved = status => status === 'DELETED'

export const getMetaTitle = ({ rating }) => {
  if (!rating || !rating.class) return null
  return `Professor ${rating.teacher.lastName}'s Rating on Rate My Professors ${
    rating.class ? `for ${rating.class}` : null
  }`
}

export const getMetaDescription = ({ rating }) => {
  return `${rating.comment ?? ''}`
}

const RatingPermalink = ({ rating, loading }) => {
  if (loading) {
    return <PageWrapper>Loading...</PageWrapper>
  }

  if (!rating) {
    return <RatingNotFound />
  }

  if (!rating.teacherVisible) {
    return <RatingNotFound />
  }

  return (
    <PageWrapper>
      <SEOMetaInfo title={getMetaTitle({ rating })} description={getMetaDescription({ rating })} />
      <AdLeaderboard code="ad_lb_top_ratings" />
      <RatingPermalinkContainer>
        <RatingPermalinkHeader rating={rating} />
        <Rating
          rating={rating}
          teacher={rating.teacher}
          displayOptions={{ showRemoved: isRemoved(rating.flagStatus), showFooter: true }}
        />
      </RatingPermalinkContainer>
      <AdRail />
    </PageWrapper>
  )
}

RatingPermalink.propTypes = {
  rating: RatingTypes,
  loading: PropTypes.bool
}

export default RatingPermalink
