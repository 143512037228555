import React from 'react'
import { graphql } from 'relay-runtime'
import { Redirect, withRouter } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import ROUTE from '@enums/ROUTE'
import QueryWrapper from '@components/utils/QueryWrapper'
import usePermalink from '@utils/usePermalink'
import getVariables from './RatingPage.utils'
import RatingPermalink from './RatingPermalink'

const query = graphql`
  query RatingPageQuery($rid: ID!) {
    rating: node(id: $rid) {
      ... on Rating {
        id
        legacyId
        class
        isForOnlineClass
        helpfulRating
        clarityRating
        difficultyRating
        difficultyRatingRounded
        iWouldTakeAgain
        isForCredit
        textbookIsUsed
        attendanceMandatory
        grade
        comment
        ratingTags
        flagStatus
        ...RatingFooter_rating
        ...RatingHeader_rating
        teacherVisible
        teacher {
          id
          legacyId
          isProfCurrentUser
          firstName
          lastName
          department
          departmentId
          school {
            legacyId
            name
            city
            state
            country
          }
          ...RatingFooter_teacher
        }
      }
    }
  }
`

export const RatingPage = ({ location }) => {
  if (!usePermalink()) {
    return <Redirect to={ROUTE.HOME} />
  }

  const variables = getVariables(location)

  if (!variables.rid) {
    return <Redirect to={ROUTE.RATING_NOT_FOUND} />
  }

  return <QueryWrapper query={query} variables={variables} component={RatingPermalink} />
}

RatingPage.propTypes = {
  location: ReactRouterPropTypes.location
}

const withRouterRatingPage = withRouter(RatingPage)
withRouterRatingPage.serverQueries = [
  {
    query,
    getVariables
  }
]

export default withRouterRatingPage
