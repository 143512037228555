import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { NEW_SEARCH_ICONS } from '@components/NewSearch'
import { DebouncedSearchInput } from '@components/Search'
import SEARCH_CATEGORY from '@enums/SEARCH_CATEGORY'
import PLACEHOLDER from '@enums/PLACEHOLDER'
import TeacherTypes from '@types/Teacher.types'
import useClickListener from '@hooks/useClickListener'
import SearchContainer from '@components/Search/SearchContainer'

const valueColor = css`
  ${({ theme }) => theme.color.gray10}
`

const placeholderColor = css`
  ${({ theme }) => theme.color.gray9}
`

const ProfessorSearch = styled(SearchContainer)`
  ${DebouncedSearchInput} {
    border: 0;
    color: ${props => (props.inputValue === '' ? placeholderColor : valueColor)};
    font-size: ${({ theme }) => theme.fontSize.teacherCardName};
    height: 56px;
    outline: none;
    padding: 16px 60px;
    width: 100%;

    ${props =>
      props.isDisabled &&
      css`
        background-color: ${({ theme }) => theme.color.white};
        pointer-events: none;
      `}

    ${props =>
      props.isClicked &&
      props.isDisabled &&
      css`
        background-color: ${({ theme }) => theme.color.white};
        border: 2px solid ${({ theme }) => theme.color.errorRed};
      `}
  }

  ${props =>
    props.isClicked &&
    css`
    border-radius: 32px;
    box-shadow: 0 2px 2px rgba(126, 126, 126, 0.25);s
  `}
`

const ProfessorSearchContainer = styled.div``

const DisabledText = styled.p`
  color: red;
  display: ${props => (props.isClicked && props.isDisabled ? 'inline-block' : 'none')};
  margin-top: 8px;
`

const CompareProfessorsSearch = ({
  handleOnItemSelected,
  isPrimaryProfessor,
  primaryProfessor,
  secondaryProfessor
}) => {
  const [value, setValue] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const isDisabled = primaryProfessor && secondaryProfessor

  const searchRef = useRef(null)

  const handleContainerClick = () => {
    setIsClicked(true)
  }

  /**
   * Design wants different styling based on whether we are clicking inside or outside
   * of the search container. This hook handles those clicks so we can change styling
   */
  useClickListener(searchRef, () => setIsClicked(false))

  return (
    /**
     * The below ProfessorSearchContainer allows for us to have a secondary
     * mouse event to control the disabled text without editing the main search component
     */
    <ProfessorSearchContainer ref={searchRef} onClick={handleContainerClick}>
      <ProfessorSearch
        searchCategory={SEARCH_CATEGORY.TEACHERS}
        setValue={setValue}
        value={value}
        onItemSelected={handleOnItemSelected}
        placeholder={PLACEHOLDER.PROF_SEARCH}
        iconType={NEW_SEARCH_ICONS.HOME_PAGE}
        isPrimaryProfessor={isPrimaryProfessor}
        isClicked={isClicked}
        isDisabled={isDisabled}
        disabled={isDisabled}
        allowRedirect={false}
        isCompareProfessorsPage
        count={25}
        useErrorText
        useErrorBorder
      />
      <DisabledText isDisabled={isDisabled} isClicked={isClicked}>
        You can only compare two professors at this time. Please remove a professor to add another.
      </DisabledText>
    </ProfessorSearchContainer>
  )
}

CompareProfessorsSearch.propTypes = {
  handleOnItemSelected: PropTypes.func,
  isPrimaryProfessor: PropTypes.bool,
  primaryProfessor: TeacherTypes,
  secondaryProfessor: TeacherTypes
}

export default CompareProfessorsSearch
